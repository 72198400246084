import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import MaintenanceOfAllTypesOfACBanner from '../../assets/images/services/maintenance-of-all-types-of-ac.jpg';
import BookAppointment from '../molecules/appointment';

const MaintenanceOfAllTypesOfAC = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log("navigatenavigatenavigate", location)

    const { state } = location

    return (
        <div className='wrapper air-condition'>

            <section className='banner'>
                <figure>
                    <img src={MaintenanceOfAllTypesOfACBanner} alt="Maintenance Of All Types Of AC Banner" title="" />
                </figure>
            </section>

            <div className='container'>
                <div className="breadcrumb-nav">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li> 
                        <li><Link to={'/services'}>Services</Link></li> 
                        <li className='active'>Maintenance Of All Types Of AC</li>
                    </ul>
                </div>
            </div>
            
            <section className='ac'>
                <div className='container'>
                    <div className='ac-details'>
                        <div className='content'>
                            <h2>Maintenance Of All Types Of AC</h2>
                            <p>Maintenance of all types of air conditioning units is important to ensure that they are functioning efficiently and effectively. Regular maintenance can help prevent breakdowns, extend the lifespan of your unit, and reduce energy bills. Here are some common AC maintenance services:</p>
                            <ul>
                                <li><span>Cleaning:</span> Our professional technician will clean the air filters, condenser coil, evaporator coil, and blower components to ensure that they are free of dust and debris. This improves the air quality and ensures that the unit is running efficiently.</li>
                                <li><span>Inspections:</span> Our technician will inspect your AC unit for signs of wear and tear, including checking the refrigerant levels, electrical connections, and thermostat settings. This can help identify potential issues before they turn into major breakdowns.</li>
                                <li><span>Repairs:</span> If your AC unit is not functioning properly, our technician can diagnose and repair any issues. Common issues include faulty motors, worn-out belts, and refrigerant leaks.</li>
                                <li><span>Replacement:</span> If your AC unit is beyond repair or is not functioning efficiently, a technician may recommend a replacement. They can help you choose a new unit that fits your needs and budget.</li>
                                <li><span>Annual Maintenance:</span> Scheduling an annual maintenance service for your AC unit can help ensure that it is functioning efficiently and effectively. During this service, our technician will clean, inspect, and repair your AC unit as necessary to prevent future breakdowns.</li>
                            </ul>
                        </div>

                        <BookAppointment />
                    </div>
                </div>
            </section>
        </div>
    )
}


export default MaintenanceOfAllTypesOfAC;