import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import ContactThankYouBanner from '../../assets/images/contact-thankyou-banner.jpg';
import ThankyouEllipse from '../../assets/images/icons/thankyou-ellipse.png';
import AppointmentThankYouBanner from '../../assets/images/book-an-appointment-banner.jpg';

const ThankYou = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    // console.log("navigatenavigatenavigate", location)

    const { state } = location

    return (
        <div className='wrapper thank-you'>

            <section className='banner'>
                <figure>
                    <img src={state === null ? AppointmentThankYouBanner : ContactThankYouBanner } alt="Thank You Banner" title="" />
                </figure>
            </section>

            <div className='container'>
                <div className="breadcrumb-nav">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li> 
                        <li className='active'>Thank You</li>
                    </ul>
                </div>
            </div>
            
            <section className='thank'>
                <div className='container'>
                    <div className='thankyou'>
                        <img src={ThankyouEllipse} alt="Ellipse" title="" className='icon' />
                        <h2>Thank You</h2>
                        <p>Your request has been send successfully.<span>Someone will be in connect with you shortly</span></p>
                        <div className='back'>
                            <Link to={'/'}  className="btn btn-yellow">Back to home</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default ThankYou;