import React from 'react';
import { Link } from 'react-router-dom';
import Calender from '../../../assets/images/icons/calender.svg';
import Call from '../../../assets/images/icons/call.svg';

const BookAppointment = () => {
    return (
        <div className='book-appointment'>
            <div className='card'>
                <div className='card-body'>
                    <h5>Schedule an appointment with our expert representative</h5>
                    <Link className="nav-link btn btn-yellow" to="/book-an-appointment">
                        <img src={Calender} alt="BOOK AN APPOINTMENT" title="BOOK AN APPOINTMENT" />BOOK AN APPOINTMENT
                    </Link>
                    <ul>
                        <li><img src={Call} alt="+91 80972 98362" title="" /></li>
                        <li>
                            <a className="" href='tel:+918097298362'>+91 80972 98362</a>
                            <a className="" href='tel:+917738477070'>+91 77384 77070</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default BookAppointment;
