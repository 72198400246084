import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';
import ServicesBanner from '../../assets/images/services-banner.jpg';
import Ellipse from '../../assets/images/icons/service-ellipse.svg';
import ACblue from '../../assets/images/icons/ac-blue.svg';
import AnnualMaintaince from '../../assets/images/icons/annual-maintaince.svg';
import ACrepair from '../../assets/images/icons/ac-repair.svg';
import InstallationBlue from '../../assets/images/icons/installation-blue.svg';
import PowerPump from '../../assets/images/icons/powerpump.svg';
import FastService from '../../assets/images/icons/fast-service.svg';
import Buy from '../../assets/images/icons/buy.svg';
import Calender from '../../assets/images/icons/calender.svg';
import Call from '../../assets/images/icons/call.svg';

const ServicesData = [
    { id:1, src:ACrepair, name:'Air Condition Repair and Services',desc: 'AC systems are essential for keeping our homes and workplaces comfortable during hot and humid weather. We provide full range of air conditioning repair and services.', url: '/services/air-condition-repair-and-services'},
    { id:2, src:AnnualMaintaince, name:'AC Annual Maintenance Service',desc: 'Annual maintenance service (AMC) is an important aspect of keeping your air conditioning unit functioning efficiently and extending its lifespan. We provide full range of air conditioning maintenance services.', url: '/services/ac-annual-maintenance-services'},
    { id:3, src:ACblue, name:'Maintenance of all types of AC',desc: 'Maintenance of all types of air conditioning units is important to ensure that they are functioning efficiently and effectively. We provide maintenance of all types of air conditioning.', url: '/services/maintenance-of-all-types-of-ac'},
    { id:4, src:InstallationBlue, name:'AC Installation',desc: 'AC installation is an important process that involves careful planning and execution to ensure that your unit is installed correctly and functioning efficiently. We provide high quality of air conditioning installation services with lower cost.', url: '/services/ac-installation'},
    { id:5, src:PowerPump, name:'Power Jet Pump Services',desc: 'A power jet pump is a type of pump that is used to transfer water from one location to another and are commonly used for agricultural, irrigation, and residential applications. We provide full range of power jet pump services.', url: '/services/power-jet-pump-services'},
    { id:6, src:FastService, name:'24/7 fast AC service for hospital, school, college',desc: 'Providing 24/7 fast AC service for hospitals, schools, and colleges is crucial to ensure that the indoor environment is comfortable and safe for patients, students, and staff.', url: '/services/fast-ac-service-for-hospital'},
    { id:7, src:Buy, name:'Buy / Sell New / Second Hand AC',desc: 'If you are looking to buy or sell a new or second-hand air conditioning unit, contact PARAS AC.', url: '/services/buy-sell-new-second-hand-ac'}
]

const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='wrapper services'>

            <section className='banner'>
                <figure>
                    <img src={ServicesBanner} alt="Services Banner" title="" />
                </figure>
            </section>

            <section className='bread'>
                <div className='container'>
                    <div className="breadcrumb-nav">
                        <ul className="breadcrumb">
                            <li><Link to={'/'}>Home</Link></li> 
                            <li className='active'>Services</li>
                        </ul>
                    </div>
                </div>
            </section>
            
            <section className='services-section'>
                <div className='container'>
                    <h2>Services</h2>
                    <div className='services-main'>
                        {
                            ServicesData.map((item,index)=>{
                                return(
                                    <Link to={item.url} key={index}>
                                        <div className='card'>
                                            <img src={Ellipse} alt="Ellipse" title="" className='ellipse' />
                                            <img src={item.src} alt={item.name} title={item.name} className='icon' />
                                            <div className='card-body'>
                                                <h3>{item.name}</h3>
                                                <span>{item.desc}</span>
                                            </div>
                                        </div>
                                    </Link>    
                                )
                            })
                        }
                        <div className='card book-card'> 
                            <div className='card-body'>
                                <h3>Schedule an appointment with our expert representative</h3>
                                <Link className="nav-link btn btn-yellow" to="/book-an-appointment">
                                    <img src={Calender} alt="BOOK AN APPOINTMENT" title="BOOK AN APPOINTMENT" />BOOK AN APPOINTMENT
                                </Link>
                                <ul>
                                    <li><img src={Call} alt="+91 80972 98362" title="" /></li>
                                    <li>
                                        <a className="" href='tel:+918097298362'>+91 80972 98362</a>
                                        <a className="" href='tel:+917738477070'>+91 77384 77070</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Services;