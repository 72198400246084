import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import PowerJetPumpServicesBanner from '../../assets/images/services/power-jet-pump-services.jpg';
import BookAppointment from '../molecules/appointment';

const PowerJetPumpServices = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log("navigatenavigatenavigate", location)

    const { state } = location

    return (
        <div className='wrapper air-condition'>

            <section className='banner'>
                <figure>
                    <img src={PowerJetPumpServicesBanner} alt="Power Jet Pump Services Banner" title="" />
                </figure>
            </section>

            <div className='container'>
                <div className="breadcrumb-nav">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li> 
                        <li><Link to={'/services'}>Services</Link></li> 
                        <li className='active'>Power Jet Pump Services</li>
                    </ul>
                </div>
            </div>
            
            <section className='ac'>
                <div className='container'>
                    <div className='ac-details'>
                        <div className='content'>
                            <h2>Power Jet Pump Services</h2>
                            <p>A power jet pump is a type of pump that is used to transfer water from one location to another. These pumps are commonly used for agricultural, irrigation, and residential applications. Power jet pump services are essential to ensure that your pump is functioning efficiently and effectively. Here are some common power jet pump services:</p>
                            <ul>
                                <li><span>Inspection and Maintenance:</span> Our professional technician will inspect and maintain your power jet pump to ensure that it is functioning correctly. This includes checking the pump's impeller, motor, and seals, and cleaning the pump to prevent clogging.</li>
                                <li><span>Repairs:</span> If your power jet pump is not functioning properly, our technician can diagnose and repair any issues. Common issues include faulty motors, worn-out impellers, and clogged pipes.</li>
                                <li><span>Replacement:</span> If your power jet pump is beyond repair or is not functioning efficiently, a technician may recommend a replacement. They can help you choose a new pump that fits your needs and budget.</li>
                                <li><span>Installation:</span> If you have purchased a new power jet pump, our technician can install it for you, ensuring that it is installed correctly and functioning properly.</li>
                            </ul>
                            <p>It's important to schedule regular maintenance for your power jet pump to prevent breakdowns and extend its lifespan. Additionally, it's important to ensure that the pump is sized appropriately for your needs and that it is installed correctly to avoid damage to the pump or surrounding property.</p>
                        </div>

                        <BookAppointment />
                    </div>
                </div>
            </section>
        </div>
    )
}


export default PowerJetPumpServices;