import {React,useEffect,useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../molecules/input';
import { alphabatesWithSpace, isValidEmail, isValidMobileNo, numberText } from "../helper/method";
import ContactBanner from '../../assets/images/contact-banner.jpg';
import Phone from '../../assets/images/icons/phone.png';
import Email from '../../assets/images/icons/email.png';
import OfficeAddress from '../../assets/images/icons/office-address.png';
import Facebook from '../../assets/images/icons/fb.png';
// import LinkedIn from '../../assets/images/icons/in.png';
import Instagram from '../../assets/images/icons/insta.png';
import Ellipse from '../../assets/images/icons/service-ellipse.svg';
import PageLoader from '../atom/loader/PageLoader';

const baseUrl = process.env.REACT_APP_BASE_URL

const Contact = () => {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const BlurHandler = () => { }

    let hasError = false;

    const [loading, setLoading] = useState(false)
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [textmessage, setTextmessage] = useState("");

    const [errors, setErrors] = useState({
        errfullName: "",
        errEmail: "",
        errMobileno: ""
    })
    
    const ChageHandler = (name, value) => {

        if (name === "fullName") {
            let check = String(value)
            if (alphabatesWithSpace(check)) {
                setFullName(check)
                handleError(name, value)
            }
        }
        if (name === "email") {
            setEmail(value)
            handleError(name, value)
        }
        if (name === "mobile") {
            let check = value
            if (numberText(check)) {
                setMobile(check)
                handleError(name, value)
            }
        }
        if(name === "textmessage"){
            setTextmessage(value)
        }
    }  
    
    const handleError = (name, value) => {

        let allState = errors;

        if (name === "fullName") {
            if (!value.length) {
                allState["errfullName"] = "Please enter your full name."
            } else if (value.length < 3) {
                allState["errfullName"] = "Name length should be atleast 3 characters."
            } else {
                allState["errfullName"] = ""
            }
        }
        if (name === "email") {
            if (!value.length) {
                allState["errEmail"] = "Please enter your Email address."
            } else if (!isValidEmail(value)) {
                allState["errEmail"] = "Invalid Email address."
            } else {
                allState["errEmail"] = ""
            }
        }
        if (name === "mobile") {
            if (!value.length) {
                allState["errMobileno"] = "Please enter your Mobile No."
            } else if (!isValidMobileNo(value)) {
                allState["errMobileno"] = "Invalid Mobile No."
            } else {
                allState["errMobileno"] = ""
            }
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }  
    
    const checkVailidation = () => {
        hasError = false;
        let allState = errors;

        if (!fullName.length) {
            allState["errfullName"] = "Please enter your full name."
            hasError = true;
        } else if (fullName.length < 3) {
            allState["errfullName"] = "Name length should be atleast 3 characters."
            hasError = true;
        } else {
            allState["errfullName"] = ""
        }
        if (!email.length) {
            allState["errEmail"] = "Please enter your email address."
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email address."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }
        if (!mobile.length) {
            allState["errMobileno"] = "Mobile no is required."
            hasError = true
        } else if (!isValidMobileNo(mobile)) {
            allState["errMobileno"] = "Invalid mobile number."
            hasError = true
        } else {
            allState["errMobileno"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }  

    const handelContact = async(e) => {
        e.preventDefault()
        if(!checkVailidation()){

            setLoading(true)
            let data = {
                "fullname": fullName,
                "email": email,
                "mobileno":mobile,
                "textmessage": textmessage
            }

            try {
                const response = await fetch(`${baseUrl}/userRequest`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
                });
                const res = await response.json();
                if(res.status === 200){
                    setLoading(false)
                    navigate("/thank-you")
                }else{
                    setLoading(false)
                    alert("server error")
                }
               }catch(error) {
                    setLoading(false)
                  console.log(error)
                  alert("internal server error")
            } 
        }
    }


    return (
        <div className='wrapper contact'>

            <section className='banner'>
                <figure>
                    <img src={ContactBanner} alt="Contact Banner" title="" />
                </figure>
            </section>

            <section className='bread'>
                <div className='container'>
                    <div className="breadcrumb-nav">
                        <ul className="breadcrumb">
                            <li><Link to={'/'}>Home</Link></li> 
                            <li className='active'>Contact</li>
                        </ul>
                    </div>
                </div>
            </section>
            
            <section className='contact-section'>
                <div className='container'>
                    <div className='outer-div'>
                        <div className='get-in-touch'>
                            <h2>Get in touch</h2>
                            <ul className="footer-link">
                                <li>
                                    <img src={Phone} className='Icon' alt="Phone number" title="" />
                                    <div>
                                        <h5>Phone number</h5>
                                        <a href="tel:+918097298362">+ 91 80972 98362</a> 
                                        <a href="tel:+917738477070">+ 91 77384 77070</a>
                                    </div>
                                </li>
                                <li>
                                    <img src={Email} className='Icon' alt="Email us" title="" />
                                    <div>
                                        <h5>Email us</h5>
                                        <a href="mailto:info@parasairconditioning.in">info@parasairconditioning.in</a>
                                    </div>
                                </li>
                                <li>
                                    <img src={OfficeAddress} className='Icon' alt="Registered Office Address" title="" />
                                    <div>
                                        <h5>Registered Office Address</h5>
                                        <p>Shop No. 11, Rock Avenue, Plot D, Near ICICI Bank, Hindustan Naka, Kandivali (West), Mumbai - 400 067, Maharashtra, India.</p>
                                    </div>
                                </li>
                            </ul>

                            <div className='connect'>
                                <hr />
                                <h5>Connect with us:</h5>
                            </div>

                            <ul className="social-link">
                                <li className="facebook"><a href={'https://www.facebook.com/people/Paras-Air-Conditioning/100063764147566/'} target="_blank" rel="noreferrer"><img src={Facebook} alt="Follow on Facebook" title="Follow on Facebook" /></a></li>
                                {/* <li className="linkedin"><a href={'https://www.linkedin.com'} target="_blank" rel="noreferrer"><img src={LinkedIn} alt="Follow on LinkedIn" title="Follow on LinkedIn" /></a></li> */}
                                <li className="instagram"><a href={'https://www.instagram.com/parasairconditioning/'} target="_blank" rel="noreferrer"><img src={Instagram} alt="Follow on Instagram" title="Follow on Instagram" /></a></li>
                            </ul>
                        </div>

                        <div className='contact-form'>
                            <img src={Ellipse} alt="Ellipse" title="" className='ellipse' />
                            <h2>Contact</h2>
                            <p>Schedule an appointment with our expert representative</p>
                                
                            <form className="form">
                                <div className="form-group">
                                    <label>Full Name</label>
                                    <TextInput
                                        name={"fullName"}
                                        id={"fullName"}
                                        type={"text"}
                                        onBlur={BlurHandler}
                                        inputClass={"form-control"}
                                        // placeholder={"Full First and Last Name"}
                                        value={fullName}
                                        onChange={ChageHandler}
                                        autoComplete="off"
                                        errorMSg={errors.errfullName}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Mobile</label>
                                    <div className="cust-select">
                                        <div className='std-code'>+91</div>
                                        <TextInput
                                            name={"mobile"}
                                            id={"mobile"}
                                            type={"tel"}
                                            onBlur={BlurHandler}
                                            maxLength={10}
                                            inputClass={"form-control"}
                                            // placeholder={"Enter Your Mobile No"}
                                            value={mobile}
                                            onChange={ChageHandler}
                                            autoComplete="off"
                                            errorMSg={errors.errMobileno}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextInput
                                        name={"email"}
                                        id={"email"}
                                        type={"text"}
                                        onBlur={BlurHandler}
                                        inputClass={"form-control"}
                                        // placeholder={"Enter Your Email Id"}
                                        value={email}
                                        onChange={ChageHandler}
                                        autoComplete="off"
                                        errorMSg={errors.errEmail}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Remarks (Optional)</label>
                                    <textarea 
                                        className='form-contrrol'
                                        placeholder='Type here your query'
                                        onChange={(e) => ChageHandler("textmessage", e.target.value)}
                                        name="textmessage"
                                        value={textmessage}
                                        rows="3"
                                        onBlur={handleError}
                                    />  
                                </div>

                                <div className="submit"><button className="btn btn-yellow" type="submit" onClick={handelContact}>SUBMIT</button></div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
            {loading &&
                <PageLoader />
            }
        </div>
    )
}

export default Contact;