import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import SecondHandACBanner from '../../assets/images/services/buy-sell-new-second-hand-ac.jpg';
import BookAppointment from '../molecules/appointment';

const SecondHandAC = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log("navigatenavigatenavigate", location)

    const { state } = location

    return (
        <div className='wrapper air-condition'>

            <section className='banner'>
                <figure>
                    <img src={SecondHandACBanner} alt="Buy / Sell New / Second Hand AC Banner" title="" />
                </figure>
            </section>

            <div className='container'>
                <div className="breadcrumb-nav">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li> 
                        <li><Link to={'/services'}>Services</Link></li> 
                        <li className='active'>Buy / Sell New / Second Hand AC</li>
                    </ul>
                </div>
            </div>
            
            <section className='ac'>
                <div className='container'>
                    <div className='ac-details'>
                        <div className='content'>
                            <h2>Buy / Sell New / Second Hand AC</h2>
                            <p>If you are looking to buy or sell a new or second-hand air conditioning unit, there are several things to keep in mind. Here are some tips to help you make an informed decision:</p>
                            <p>Buying a New or Second-Hand AC:</p>
                            <ul>
                                <li><span>Determine Your Needs:</span> Determine the size and type of air conditioning unit that you need based on the size of your space and your cooling requirements.</li>
                                <li><span>Research Brands and Models:</span> Research different brands and models of air conditioning units to find one that fits your needs and budget. Look for models with good energy ratings and warranties.</li>
                                <li><span>Buy from a Reputable Dealer:</span> Purchase your new or second-hand AC from a reputable dealer or retailer. If you are buying a second-hand unit, ask for its service history and check it for any signs of damage or wear and tear.</li>
                                <li><span>Consider Installation and Maintenance Costs:</span> Remember to factor in the cost of installation and maintenance when purchasing a new or second-hand air conditioning unit.</li>
                            </ul>

                            <p>Selling a New or Second-Hand AC:</p>
                            <ul>
                                <li><span>Determine the Value:</span> Determine the value of your AC unit by researching similar models and their prices. Consider the age and condition of your unit when setting a price.</li>
                                <li><span>Clean and Prepare the Unit:</span> Clean your AC unit and ensure that it is functioning properly before listing it for sale. Make any necessary repairs or replacements to ensure that it is in good condition.</li>
                                <li><span>Advertise Your Unit:</span> Advertise your AC unit for sale online or through local classifieds. Include detailed information about the unit, including its brand, model, age, and condition.</li>
                                <li><span>4.	Negotiate and Close the Sale:</span> Negotiate with potential buyers and close the sale once you have agreed on a price. Ensure that you have a written agreement that outlines the terms of the sale and any warranties or guarantees.</li>
                            </ul>

                            <p>Remember to always prioritize safety and seek the advice from PARAS AC when dealing with air conditioning units.</p>
                        </div>

                        <BookAppointment />
                    </div>
                </div>
            </section>
        </div>
    )
}


export default SecondHandAC;