import {React,useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo-paras.png';
import Call from '../../assets/images/icons/call.svg';
import Time from '../../assets/images/icons/time.svg';
import CallWhite from '../../assets/images/icons/call-white.svg';
import TimeWhite from '../../assets/images/icons/time-white.svg';
import Calender from '../../assets/images/icons/calender.svg';
import WhatsAppChat from '../../assets/images/icons/wa-icon.svg';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Header = () => {
    const [isActive, setActive] = useState("");
    const [show, setShow] = useState(false);

    const location = useLocation();

    const handleToggle = (value) => {
        setActive(value);
    };

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    const url = location;
    // console.log('location',location)
    // console.log('url',url)
    // if(url === '/about'){
    //     console.log('url',url)
    // } else if(url === "/services"){
    //     console.log('url',url)
    // }
    
    return <>
    <header>
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className='container'>
            <button className="navbar-toggler">
                <div className="logo">
                    <Link className="navbar-brand" to={'/'} >
                        <img src={Logo} alt="Paras Airconditioning Sales & Services" title="Paras Airconditioning Sales & Services" />
                    </Link>
                </div>
                <span className="navbar-toggler-icon" onClick={toggleShow}></span>
            </button>

            <Offcanvas show={show} onHide={handleClose} placement='end'>
                <Offcanvas.Header closeButton>
                    {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="nav">
                        <ul className="navbar-nav top-nav mr-auto">
                            <li className="nav-item call">
                                <img src={CallWhite} alt="+91 80972 98362" title="" />
                                <a className="nav-link" href='tel:+918097298362' onClick={toggleShow}>+91 80972 98362</a> 
                                <span> | </span>
                                <a className="nav-link" href='tel:+917738477070' onClick={toggleShow}>77384 77070</a> 
                            </li>
                            <li className="nav-item time">
                                <img src={TimeWhite} alt="Mon-Sat: 10:00 AM - 07:00 PM" title="" />
                                <Link className="nav-link" to="">Mon-Sat: 10:00 AM - 07:00 PM</Link>
                            </li>
                        </ul>    
                        <ul className="navbar-nav bottom-nav mr-auto">
                            <li className={`nav-item ${isActive === 1 ? "active" : ""}`}>
                                <Link className="nav-link" to="/about" onClick={toggleShow}>About <span className="sr-only">(current)</span></Link>
                            </li>
                            <li className={`nav-item ${isActive === 2 ? "active" : ""}`}>
                                <Link className="nav-link" onClick={toggleShow} to="/services">Services</Link>
                            </li>
                            <li className={`nav-item ${isActive === 3 ? "active" : ""}`}>
                                <Link className="nav-link" onClick={toggleShow} to="/clients">Clients</Link>
                            </li>
                            <li className={`nav-item ${isActive === 4 ? "active" : ""}`}>
                                <Link className="nav-link" onClick={toggleShow} to="/contact">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link btn btn-yellow" to="/book-an-appointment" onClick={toggleShow}>
                                    <img src={Calender} alt="BOOK AN APPOINTMENT" title="BOOK AN APPOINTMENT" />BOOK AN APPOINTMENT
                                </Link>
                            </li>
                        </ul>
                    </div> 
                </Offcanvas.Body>
            </Offcanvas>
        
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className='inner-nav'>
                    <div className="logo">
                        <Link className="navbar-brand" to={'/'}>
                            <img src={Logo} alt="Paras AC" title="Paras AC" />
                        </Link>
                    </div>

                    <div className="nav">
                        <ul className="navbar-nav top-nav mr-auto">
                            <li className="nav-item call">
                                <img src={Call} alt="+91 80972 98362" title="" />
                                <a className="nav-link" href='tel:+918097298362'>+91 80972 98362</a> |
                                <a className="nav-link" href='tel:+917738477070'>77384 77070</a> 
                            </li>
                            <li className="nav-item time">
                                <img src={Time} alt="Mon-Sat: 10:00 AM - 07:00 PM" title="" />
                                <Link className="nav-link" to="">Mon-Sat: 10:00 AM - 07:00 PM</Link>
                            </li>
                            <li className="nav-item">
                                <div className="rcb-btn">
                                    <Link to="https://wa.me/918097298362" target='_blank'>
                                        <img src={WhatsAppChat} alt='Chat with us' title='Chat with us' />
                                    </Link>
                                </div>
                            </li>
                        </ul>    
                        <ul className="navbar-nav bottom-nav mr-auto">
                            <li className={`nav-item ${url == '/about' ? "active" : "klgui"}`}>
                                <Link className="nav-link" to="/about">About <span className="sr-only">(current)</span></Link>
                            </li>
                            <li className={`nav-item ${url == '/services' ? "active" : "lui"}`}>
                                <Link className="nav-link" to="/services">Services</Link>
                                <div class="dropdown-content">
                                    <Link to="/services/air-condition-repair-and-services">Air Condition Repair and Services</Link>
                                    <Link to="/services/ac-annual-maintenance-services">AC Annual Maintenance Service</Link>
                                    <Link to="/services/maintenance-of-all-types-of-ac">Maintenance of all types of AC</Link>
                                    <Link to="/services/ac-installation">AC Installation</Link>
                                    <Link to="/services/power-jet-pump-services">Power Jet Pump Services</Link>
                                    <Link to="/services/fast-ac-service-for-hospital">24/7 fast AC service for Hospital, School, College</Link>
                                    <Link to="/services/buy-sell-new-second-hand-ac">Buy / Sell New / Second Hand AC</Link>
                                </div>
                            </li>
                            <li className={`nav-item ${url == '/clients' ? "active" : "lyui"}`}>
                                <Link className="nav-link" to="/clients">Clients</Link>
                            </li>
                            <li className={`nav-item ${url == '/contact' ? "active" : "lyuh"}`}>
                                <Link className="nav-link" to="/contact">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link btn btn-yellow" to="/book-an-appointment">
                                    <img src={Calender} alt="BOOK AN APPOINTMENT" title="BOOK AN APPOINTMENT" />BOOK AN APPOINTMENT
                                </Link>
                            </li>
                        </ul>
                    </div> 
                </div>
            </div>    
        </div>
    </nav>
  </header>
</>
}

export default Header;