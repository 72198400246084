import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import ACInstallationBanner from '../../assets/images/services/ac-installation.jpg';
import BookAppointment from '../molecules/appointment';

const ACInstallation = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log("navigatenavigatenavigate", location)

    const { state } = location

    return (
        <div className='wrapper air-condition'>

            <section className='banner'>
                <figure>
                    <img src={ACInstallationBanner} alt="AC Installation Banner" title="" />
                </figure>
            </section>

            <div className='container'>
                <div className="breadcrumb-nav">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li> 
                        <li><Link to={'/services'}>Services</Link></li> 
                        <li className='active'>AC Installation</li>
                    </ul>
                </div>
            </div>
            
            <section className='ac'>
                <div className='container'>
                    <div className='ac-details'>
                        <div className='content'>
                            <h2>AC Installation</h2>
                            <p>Air conditioning installation is an important process that involves careful planning and execution to ensure that your unit is installed correctly and functioning efficiently. Here are some important steps we follow when installing an air conditioning unit:</p>
                            <ul>
                                <li><span>Determine the Correct Size:</span> Before installing your air conditioning unit, it's important to determine the correct size for your space. An oversized or undersized unit can lead to inefficiency and higher energy bills. A professional technician can perform a load calculation to determine the appropriate size of your unit.</li>
                                <li><span>Choose the Right Location:</span> Choose a location for your air conditioning unit that is away from direct sunlight, where there is proper ventilation and access for maintenance. The unit should be placed on a level surface and away from any obstructions that could block airflow.</li>
                                <li><span>Install the Ductwork:</span> If your air conditioning unit requires ductwork, a professional technician will install it before installing the unit. The ductwork should be sized appropriately for your space and insulated to prevent energy loss.</li>
                                <li><span>Connect the Electrical Wiring:</span> Our professional electrician will connect the electrical wiring for your air conditioning unit. This includes connecting the power supply and ensuring that the electrical panel is properly sized for the unit.</li>
                                <li><span>Test and Inspect:</span> Once the unit are installed, a technician will test and inspect the system to ensure that it is functioning properly. This includes checking the refrigerant levels, airflow, and thermostat settings.</li>
                            </ul>
                        </div>

                        <BookAppointment />
                    </div>
                </div>
            </section>
        </div>
    )
}


export default ACInstallation;