import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import ACAnnualMaintenanceServiceBanner from '../../assets/images/services/ac-annual-maintenance-service.jpg';
import BookAppointment from '../molecules/appointment';

const ACAnnualMaintenanceService = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log("navigatenavigatenavigate", location)

    const { state } = location

    return (
        <div className='wrapper air-condition'>

            <section className='banner'>
                <figure>
                    <img src={ACAnnualMaintenanceServiceBanner} alt="AC Annual Maintenance Service Banner" title="" />
                </figure>
            </section>

            <div className='container'>
                <div className="breadcrumb-nav">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li> 
                        <li><Link to={'/services'}>Services</Link></li> 
                        <li className='active'>AC Annual Maintenance Service</li>
                    </ul>
                </div>
            </div>
            
            <section className='ac'>
                <div className='container'>
                    <div className='ac-details'>
                        <div className='content'>
                            <h2>AC Annual Maintenance Service</h2>
                            <p>Annual maintenance service is an important aspect of keeping your air conditioning unit functioning efficiently and extending its lifespan. Here are some of the benefits of scheduling an annual maintenance service for your AC:</p>
                            <ul>
                                <li><span>Improved Efficiency:</span> During an annual maintenance service, our technician will clean and inspect your AC unit, including the evaporator coil, condenser coil, and blower components. This cleaning ensures that your unit is running at maximum efficiency, reducing your energy bills.</li>
                                <li><span>Fewer Breakdowns:</span> Regular maintenance can help identify potential issues before they turn into major breakdowns. Our technician will inspect your unit for any signs of wear and tear and make necessary repairs to prevent future breakdowns.</li>
                                <li><span>Longer Lifespan:</span> Proper maintenance can extend the lifespan of your air conditioning unit. By ensuring that your unit is running efficiently and making necessary repairs, you can prevent premature failure of your unit.</li>
                                <li><span>Improved Air Quality: </span> A clean air conditioning unit will provide better air quality for your home or business. During an annual maintenance service, the technician will replace your air filter, which will remove pollutants and allergens from the air.</li>
                                <li><span>Cost Savings: </span> Regular maintenance can save you money in the long run. By preventing breakdowns and extending the lifespan of your unit, you can avoid the cost of replacing your air conditioning unit prematurely.</li>
                            </ul>
                        </div>

                        <BookAppointment />
                    </div>
                </div>
            </section>
        </div>
    )
}


export default ACAnnualMaintenanceService;