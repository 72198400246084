import React from "react";
import { Routes, Route } from 'react-router-dom'
import Home from '../component/pages/home'
import About from '../component/pages/about'
import Contact from '../component/pages/contact'
import Header from "./shared/header";
import Footer from "./shared/footer";
import ThankYou from '../component/pages/thankyou'
import Services from '../component/pages/services'
import Clients from '../component/pages/clients'
import BookAnAppointment from '../component/pages/bookanappointment'
import AirConditionRepairAndServices from '../component/pages/AirConditionRepairAndServices'
import ACAnnualMaintenanceService from '../component/pages/ACAnnualMaintenanceService'
import MaintenanceOfAllTypesOfAC from '../component/pages/MaintenanceOfAllTypesOfAC'
import ACInstallation from '../component/pages/ACInstallation'
import PowerJetPumpServices from '../component/pages/PowerJetPumpServices'
import FastACServiceForHospital from '../component/pages/FastACServiceForHospital'
import SecondHandAC from '../component/pages/SecondHandAC'

const  App = () => {
   return (
    <div className="App">
      <Header />
      <Routes>
        <Route element={<Home />} exact  path={"/"} />
        <Route element={<About />} exact path={"/about"} />
        <Route element={<Services />} exact path={"/services"} />
        <Route element={<Clients />} exact path={"/clients"} />
        <Route element={<Contact />} exact path={"/contact"} />
        <Route element={<BookAnAppointment />} exact path={"/book-an-appointment"} />
        <Route element={<ThankYou />} exact path={"/thank-you"} />
        <Route element={<AirConditionRepairAndServices />} exact path={"/services/air-condition-repair-and-services"} />
        <Route element={<ACAnnualMaintenanceService />} exact path={"/services/ac-annual-maintenance-services"} />
        <Route element={<MaintenanceOfAllTypesOfAC />} exact path={"/services/maintenance-of-all-types-of-ac"} />
        <Route element={<ACInstallation />} exact path={"/services/ac-installation"} />
        <Route element={<PowerJetPumpServices />} exact path={"/services/power-jet-pump-services"} />
        <Route element={<FastACServiceForHospital />} exact path={"/services/fast-ac-service-for-hospital"} />
        <Route element={<SecondHandAC />} exact path={"/services/buy-sell-new-second-hand-ac"} />
      </Routes>
      <Footer />
    </div>
    
  );
}

export default App;
