import React from 'react'
import { Link } from 'react-router-dom';
import WelcomeImg from '../../assets/images/welcome.png';

const Welcome = () => {
  return (
    <ul>
        <li>
            <figure>
                <img src={WelcomeImg} alt="Welcome to Paras Air Conditioning" title="Welcome to Paras Air Conditioning" />
            </figure>
        </li>
        <li>
            <h5>Welcome to Paras Air Conditioning</h5>
            <h3>Experts in all types of <span> Air Conditioning</span></h3>
            {/* <hr /> */}
            <div className='home-welcome'>
                <p><span>Paras Air Conditioning</span> have entered into this growing and competitive industry in the year <span>2005</span>, started singled handed, now we have manpower of 10 people which works across in and around <span>Mumbai City ...</span></p>

                <Link to={'/about'} className="btn btn-yellow">Read More</Link>
            </div>

            <div className='about-welcome'>
                <p>We provide <span>repairs and service</span> for all brands and types of air conditioners including split, window, ductables & other centralized systems such as VRF (Variable Refrigerant Flow) and AHUs (Air Handling Units).</p>
                <p>We have a team of highly skilled & experienced technicians specialized in air conditioning installations, repairs, servicing and maintenance.</p>
                <p>With over <span>15 years of experience,</span> we are a trusted name in Air Conditioning service in Mumbai.</p>
                <p>We offer same day service with a turn around time of around <span>2 hours.</span></p>
                <p>Keep Your Air Conditioning Unit running smoothly with AC repairs and services.</p>
            </div>
        </li>
    </ul>
  )
}

export default Welcome;