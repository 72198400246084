import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';
import ClientsBanner from '../../assets/images/clients-banner.jpg';
import One from '../../assets/images/numbers/one.png';
import Two from '../../assets/images/numbers/two.png';
import Three from '../../assets/images/numbers/three.png';
import Four from '../../assets/images/numbers/four.png';
import Five from '../../assets/images/numbers/five.png';
import Six from '../../assets/images/numbers/six.png';
import Seven from '../../assets/images/numbers/seven.png';

const ClientsData = [
    { id:1, src:One, name:'Technocrats Security Systems'},
    { id:2, src:Two, name:'Unique Creations ( interior Designer )'},
    { id:3, src:Three, name:'R K Design (Turnkey Contractor)'},
    { id:4, src:Four, name:'Shapoorji Pallonji'},
    { id:5, src:Five, name:'Akshay Investment'},
    { id:6, src:Six, name:'Shreeji Interior Designs'},
    { id:7, src:Seven, name:'Sublimis Technologies Private Limited'},
]

const Clients = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='wrapper clients'>

            <section className='banner'>
                <figure>
                    <img src={ClientsBanner} alt="Clients Banner" title="" />
                </figure>
            </section>

            <section className='bread'>
                <div className='container'>
                    <div className="breadcrumb-nav">
                        <ul className="breadcrumb">
                            <li><Link to={'/'}>Home</Link></li> 
                            <li className='active'>Clients</li>
                        </ul>
                    </div>
                </div>
            </section>
            
            <section className='clients-section'>
                <div className='container'>
                    <h2>Clients</h2>

                    <div className='clients-main'>
                        {
                            ClientsData.map((item,index)=>{
                                return(
                                    <div className='card' key={index}>
                                        <div className='card-body'>
                                            <img src={item.src} alt={item.name} title={item.name} className='icon' />
                                            <h3>{item.name}</h3>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>    
                </div>
            </section>

        </div>
    )
}

export default Clients;