import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import HomeBanner1 from '../../assets/images/home-banner1.jpg';
import HomeBanner2 from '../../assets/images/home-banner2.jpg';
import HomeBanner3 from '../../assets/images/home-banner3.jpg';
import HomeBanner4 from '../../assets/images/home-banner4.jpg';
import Call from '../../assets/images/icons/call.svg';
import FreeQuote from '../../assets/images/icons/free-quote.svg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Daikin from '../../assets/images/brands/daikin.svg';
import LG from '../../assets/images/brands/lg.svg';
import Panasonic from '../../assets/images/brands/panasonic.svg';
import Samsung from '../../assets/images/brands/samsung.svg';
import Bluestar from '../../assets/images/brands/bluestar.svg';
import Haier from '../../assets/images/brands/haier.svg';
import Mask from '../../assets/images/icons/mask.svg';
import Sanitisation from '../../assets/images/icons/sanitisation.svg';
import Aarogya from '../../assets/images/icons/aarogya-setu.svg';
import Ellipse from '../../assets/images/icons/service-ellipse.svg';
import ACblue from '../../assets/images/icons/ac-blue.svg';
import AnnualMaintaince from '../../assets/images/icons/annual-maintaince.svg';
import ACrepair from '../../assets/images/icons/ac-repair.svg';
import InstallationBlue from '../../assets/images/icons/installation-blue.svg';
import PowerPump from '../../assets/images/icons/powerpump.svg';
import FastService from '../../assets/images/icons/fast-service.svg';
import Buy from '../../assets/images/icons/buy.svg';
import Quality1 from '../../assets/images/well-trained-quality-staff.jpg';
import Quality2 from '../../assets/images/best-in-class-technology.jpg';
import Quality3 from '../../assets/images/serviced-customers.jpg';
import Quality4 from '../../assets/images/all-brand-ac-maintenance.jpg';
import Quality5 from '../../assets/images/best-in-class-service.jpg';
import Welcome from '../Welcome';

const BannerData = [
    { id:1, src:HomeBanner1, alt:'The Best AC Maintance and Complete Solution', title1:'The Best', title2:'AC Maintance and', title3:'Complete Solution'},
    { id:2, src:HomeBanner2, alt:'Our Service is a breath of Fresh Air', title1:'Our Service', title2:'is a breath of', title3:'Fresh Air'},
    { id:3, src:HomeBanner3, alt:'We Put The soul in Climate Control', title1:'We Put', title2:'The soul in', title3:'Climate Control'},
    { id:4, src:HomeBanner4, alt:'Quality Service at Affordable Prices', title1:'Quality', title2:'Service at', title3:'Affordable Prices'}
]

const ServicesData = [
    { id:1, src:ACrepair, name:'Air Condition Repair and Services',desc: 'AC systems are essential for keeping our homes and workplaces comfortable during hot and humid weather. We provide full range of air conditioning repair and services.', url: '/services/air-condition-repair-and-services'},
    { id:2, src:AnnualMaintaince, name:'AC Annual Maintenance Service',desc: 'Annual maintenance service (AMC) is an important aspect of keeping your air conditioning unit functioning efficiently and extending its lifespan. We provide full range of air conditioning maintenance services.', url: '/services/ac-annual-maintenance-services'},
    { id:3, src:ACblue, name:'Maintenance of all types of AC',desc: 'Maintenance of all types of air conditioning units is important to ensure that they are functioning efficiently and effectively. We provide maintenance of all types of air conditioning.', url: '/services/maintenance-of-all-types-of-ac'},
    { id:4, src:InstallationBlue, name:'AC Installation',desc: 'AC installation is an important process that involves careful planning and execution to ensure that your unit is installed correctly and functioning efficiently. We provide high quality of air conditioning installation services with lower cost.', url: '/services/ac-installation'},
    { id:5, src:PowerPump, name:'Power Jet Pump Services',desc: 'A power jet pump is a type of pump that is used to transfer water from one location to another and are commonly used for agricultural, irrigation, and residential applications. We provide full range of power jet pump services.', url: '/services/power-jet-pump-services'},
    { id:6, src:FastService, name:'24/7 fast AC service for hospital, school, college',desc: 'Providing 24/7 fast AC service for hospitals, schools, and colleges is crucial to ensure that the indoor environment is comfortable and safe for patients, students, and staff.', url: '/services/fast-ac-service-for-hospital'},
    { id:7, src:Buy, name:'Buy / Sell New / Second Hand AC',desc: 'If you are looking to buy or sell a new or second-hand air conditioning unit, contact PARAS AC.', url: '/services/buy-sell-new-second-hand-ac'}
]

const BrandData = [
    { id:1, src:Daikin, name:'Daikin'},
    { id:2, src:LG, name:'LG'},
    { id:3, src:Panasonic, name:'Panasonic'},
    { id:4, src:Samsung, name:'Samsung'},
    { id:5, src:Bluestar, name:'Bluestar'},
    { id:6, src:Haier, name:'Haier'}
]

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const banner = {
        dots: false,
        // arrows: false,
        autoplay: false,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

      const services = {
        dots: false,
        autoplay: false,
        infinite: true,
        speed: 900,
        centerMode: true,
        centerPadding: 0,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 799,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                }
            },
            {
                breakpoint: 699,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
          ]
    }

    const brands = {
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 900,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 799,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 599,
                settings: {
                  slidesToShow: 1,
                }
            }
          ]
    }

    return (
        <div className='wrapper home'>

            <section className='home-banner'>
                <Slider {...banner}>
                    {
                        BannerData.map((item,index)=>{
                            return(
                                <figure key={index}>
                                    <img src={item.src} alt={item.alt} title={item.alt} />
                                    <figcaption>
                                    <h1>{item.title1} <span>{item.title2}</span> <span>{item.title3}</span></h1>
                                    <a href='tel:+918097298362' className='btn btn-white'>
                                        <img src={Call} alt="+91 80972 98362" title=" +91 80972 98362" /> 
                                        <span>+91 80972 98362</span>
                                    </a>
                                    <Link to={'/book-an-appointment'} className='btn btn-yellow'>
                                        <img src={FreeQuote} alt="Get a Free Quote" title="Get a Free Quote" /> 
                                        <span>Get a Free Quote</span>
                                    </Link>
                                    </figcaption>
                                    <a href='tel:+918097298362' className='btn btn-white d-sm-none'>
                                        <img src={Call} alt="+91 80972 98362" title=" +91 80972 98362" /> 
                                    </a>
                                    <Link to={'/book-an-appointment'} className='btn btn-yellow d-sm-none'>
                                        <img src={FreeQuote} alt="Get a Free Quote" title="Get a Free Quote" /> 
                                    </Link>
                                </figure>
                            )
                        })
                    }
                </Slider>
            </section>

            <section className='our-services'>
                <div className='container'>
                    <h2>Our Services</h2>

                    <div className='slider-container'>
                        <Slider {...services}>
                            {
                                ServicesData.map((item,index)=>{
                                    return(
                                        <Link to={item.url}>
                                            <div className='card' key={index}>
                                                <img src={Ellipse} alt="Ellipse" title="" className='ellipse' />
                                                <img src={item.src} alt={item.name} title={item.name} className='icon' />
                                                <div className='card-body'>
                                                    <h3>{item.name}</h3>
                                                    <span>{item.desc}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                            })
                            }
                        </Slider>
                    </div>

                    <div className='explore'>
                        <Link to={'/services'}  className="btn btn-yellow">Explore all sevices</Link>
                    </div>
                </div>    
            </section>

            <section className='welcome'>
                <div className='container'>
                    <Welcome />
                </div>
            </section>

            <section className='quality-staff'>
                <ul className=''>
                    <li>
                        <img src={Quality1} alt="Well Trained Quality Staff" title="Well Trained Quality Staff" />
                        <div className='image-overlay'><h2>Well Trained Quality Staff</h2></div>
                    </li>
                    <li>
                        <img src={Quality2} alt="Best in Class Technology and Equipment" title="Best in Class Technology and Equipment" />
                        <div className='image-overlay'><h2>Best in Class Technology and Equipment</h2></div>
                    </li>
                    <li>
                        <img src={Quality3} alt="2000 + Serviced Customers" title="2000 + Serviced Customers" />
                        <div className='image-overlay'><h2>2000 + Serviced Customers</h2></div>
                    </li>
                    <li>
                        <img src={Quality4} alt="All Brand AC Maintenance" title="All Brand AC Maintenance" />
                        <div className='image-overlay'><h2>All Brand AC Maintenance</h2></div>
                    </li>
                    <li>
                        <img src={Quality5} alt="Best in Class Service" title="Best in Class Service" />
                        <div className='image-overlay'><h2>Best in Class Service</h2></div>
                    </li>
                </ul>
            </section>

            <section className='brands-we-serve'>
                <div className='container'>
                    <h2>Brands We Serve</h2>

                        <div className='slider-container'>
                            <Slider {...brands}>
                                {
                                    BrandData.map((item,index)=>{
                                        return(
                                            <div className='brand-img' key={index}>
                                                <figure>
                                                    <img src={item.src} alt='Brand' title='Brand'/>
                                                </figure>
                                            </div>
                                        )
                                })
                                }
                            </Slider>
                        </div>
                </div>
            </section>

            <section className='safety-measures'>
                <div className='container'>
                    <h2>We Assure Best-in-Class Covid Safety Measures</h2>

                    <ul>
                        <li>
                            <img src={Mask} alt="Usage of Mask & Glove" title="Usage of Mask & Glove" />
                            <h5>Usage of Mask & Glove</h5>
                        </li>
                        <li>
                            <img src={Sanitisation} alt="Sanitization of Tools & Area" title="Sanitization of Tools & Area" />
                            <h5>Sanitization of Tools & Area</h5>
                        </li>
                        <li>
                            <img src={Aarogya} alt="Aarogya Setu to Ensure Safety" title="Aarogya Setu to Ensure Safety" />
                            <h5>Aarogya Setu to Ensure Safety</h5>
                        </li>
                    </ul>
                </div>
            </section>

        </div>
    )
}


export default Home;