import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import FastACServiceForHospitalBanner from '../../assets/images/services/fast-ac-service-for-hospital.jpg';
import BookAppointment from '../molecules/appointment';

const FastACServiceForHospital = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log("navigatenavigatenavigate", location)

    const { state } = location

    return (
        <div className='wrapper air-condition'>

            <section className='banner'>
                <figure>
                    <img src={FastACServiceForHospitalBanner} alt="24/7 fast AC service for hospital, school, college Banner" title="" />
                </figure>
            </section>

            <div className='container'>
                <div className="breadcrumb-nav">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li> 
                        <li><Link to={'/services'}>Services</Link></li> 
                        <li className='active'>24/7 fast AC service for hospital, school, college</li>
                    </ul>
                </div>
            </div>
            
            <section className='ac'>
                <div className='container'>
                    <div className='ac-details'>
                        <div className='content'>
                            <h2>24/7 fast AC service for hospital, school, college</h2>
                            <p>Providing 24/7 fast AC service for hospitals, schools, and colleges is crucial to ensure that the indoor environment is comfortable and safe for patients, students, and staff. Here are some key considerations which we do for providing such services:</p>
                            <ul>
                                <li><span>Emergency Response Plan:</span> We provide an emergency response plan for your AC service team to ensure that they can respond quickly to any urgent requests for service. This may involve having a dedicated team available around the clock, with backup personnel in case of illness or other emergencies.</li>
                                <li><span>Regular Maintenance:</span> Regular maintenance is key to preventing breakdowns and ensuring that your AC units are functioning optimally. We schedule regular maintenance for your units to avoid costly repairs or replacements.</li>
                                <li><span>Priority Service:</span> Hospitals, schools, and colleges will receive priority service from our AC service team. We ensure that our team understands the importance of responding quickly to requests from these institutions.</li>
                                <li><span>Experienced Technicians:</span> We hire experienced technicians who are familiar with the unique needs of hospitals, schools, and colleges. They provide quickly diagnose and repair issues, while minimizing any disruption to the environment.</li>
                                <li><span>Communication:</span> We establish clear lines of communication with your clients to ensure that they can quickly and easily request AC services. We provide a system for tracking service requests and following up with clients to ensure that their needs are being met.</li>
                            </ul>
                        </div>

                        <BookAppointment />
                    </div>
                </div>
            </section>
        </div>
    )
}


export default FastACServiceForHospital;