import React from 'react';
import { Link } from 'react-router-dom';
import LogoWhite from '../../assets/images/logo-paras-white.png';
import Facebook from '../../assets/images/icons/fb.png';
// import LinkedIn from '../../assets/images/icons/in.png';
import Instagram from '../../assets/images/icons/insta.png';
import Address from '../../assets/images/icons/location.png';
import Call from '../../assets/images/icons/call-white.svg';
import Time from '../../assets/images/icons/time-white.svg';
import Mail from '../../assets/images/icons/mail-white.svg';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <ul className="footer-list">
                    <li className="social">
                        <div className="Footerlogo">
                            <img src={LogoWhite} alt="Paras Airconditioning Sales & Services" title="Paras Airconditioning Sales & Services" />
                        </div>
                    </li>
                    <li>
                        <ul>
                            <li className='AddressFooter'>
                                <ul className="address">
                                    <li> <img src={Address} className='Location' alt="Address" title="Address" /> Shop No. 11, Rock Avenue, Plot D, Near ICICI Bank, Hindustan Naka, Kandivali (W), Mumbai - 400 067, Maharashtra, INDIA</li>    
                                </ul>
                                <ul className="social-link">
                                    <li className="facebook"><a href={'https://www.facebook.com/people/Paras-Air-Conditioning/100063764147566/'} target="_blank" rel="noreferrer"><img src={Facebook} alt="Follow on Facebook" title="Follow on Facebook" /></a></li>
                                    {/* <li className="linkedin"><a href={'https://www.linkedin.com'} target="_blank" rel="noreferrer"><img src={LinkedIn} alt="Follow on LinkedIn" title="Follow on LinkedIn" /></a></li> */}
                                    <li className="instagram"><a href={'https://www.instagram.com/parasairconditioning/'} target="_blank" rel="noreferrer"><img src={Instagram} alt="Follow on Instagram" title="Follow on Instagram" /></a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className='ContactFooter'>
                                <ul className="footer-link">
                                <li><img src={Call} className='Icon' alt="+ 91 80972 98362" title="+ 91 80972 98362" /><a href="tel:+918097298362">+ 91 80972 98362</a> | <a href="tel:+917738477070">77384 77070</a></li>
                                <li><img src={Time} className='Icon' alt="Mon-Sat: 10:00 AM - 07:00 PM" title="Mon-Sat: 10:00 AM - 07:00 PM" />Mon-Sat: 10:00 AM - 07:00 PM</li>
                                <li><img src={Mail} className='Icon' alt="info@parasairconditioning.in" title="info@parasairconditioning.in" /><a  href="mailto:info@parasairconditioning.in">info@parasairconditioning.in </a></li>         
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>  
            </div>
            <ul className="copyright">
                <div className='container'>
                    <li>© 2023 <strong>Paras Air Conditioning.</strong> <span>All rights reserved.</span></li>
                    <li>Powered by <Link to={'https://www.sublimis.tech/'} target="_blank"><strong>Sublimis</strong></Link> <Link to={'https://www.sublimis.tech/'} target="_blank" ><strong>- ePorium</strong></Link></li>
                </div>
            </ul>
        </footer>
    )
}


export default Footer;