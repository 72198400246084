import {React,useEffect,useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../molecules/input';
import { alphabatesWithSpace, isValidEmail, isValidMobileNo, numberText } from "../helper/method";
import Reactselect from "../molecules/select";
import BookAnAppointmentBanner from '../../assets/images/book-an-appointment-banner.jpg';
import Ellipse from '../../assets/images/icons/service-ellipse.svg';
import BookAppointment from '../../assets/images/icons/book-appointment.svg';
import PageLoader from '../atom/loader/PageLoader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment/moment';

const baseUrl = process.env.REACT_APP_BASE_URL

const BookAnAppointment = () => {

    const navigate = useNavigate();
    let hasError = false;

    const [loading, setLoading] = useState(false)
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [service, setService] = useState("");
    const [textmessage, setTextmessage] = useState("");
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [appointmentTime, setAppointmentTime] = useState("");

    const serviceData = [
        { id: 1, value: "Air Condition Repair and Services", label: "Air Condition Repair and Services"},
        { id: 2, value: "AC Annual Maintenance Service", label: "AC Annual Maintenance Service"},
        { id: 3, value: "Maintenance of all types of AC", label: "Maintenance of all types of AC"},
        { id: 4, value: "AC Installation", label: "AC Installation"},
        { id: 4, value: "Power Jet Pump Services", label: "Power Jet Pump Services"},
        { id: 4, value: "24/7 fast AC service for Hospital, School, College", label: "24/7 fast AC service for Hospital, School, College"},
        { id: 4, value: "Buy / Sell New / Second Hand AC", label: "Buy / Sell New / Second Hand AC"},
    ]

    const appointmentTimeData = [
        { id: 1, value: "10:00 am - 11:00 am", label: "10:00 am - 11:00 am"},
        { id: 2, value: "11:00 am - 12:00 pm", label: "11:00 am - 12:00 pm"},
        { id: 3, value: "12:00 pm - 01:00 pm", label: "12:00 pm - 01:00 pm"},
        { id: 4, value: "01:00 pm - 02:00 pm", label: "01:00 pm - 02:00 pm"},
        { id: 5, value: "02:00 pm - 03:00 pm", label: "02:00 pm - 03:00 pm"},
        { id: 5, value: "03:00 pm - 04:00 pm", label: "03:00 pm - 04:00 pm"},
        { id: 5, value: "04:00 pm - 05:00 pm", label: "04:00 pm - 05:00 pm"},
        { id: 5, value: "05:00 pm - 06:00 pm", label: "05:00 pm - 06:00 pm"},
        { id: 5, value: "06:00 pm - 07:00 pm", label: "06:00 pm - 07:00 pm"},
    ]

    const [errors, setErrors] = useState({
        errfullName: "",
        errEmail: "",
        errMobileno: "",
        errService: "",
        errAppointmentDate: "",
        errAppointmentTime: "",
    })

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const BlurHandler = () => { }

    
    
    const ChangeHandler = (name, value) => {

        console.log("object name and value", name, value)

        if (name === "fullName") {
            let check = String(value)
            if (alphabatesWithSpace(check)) {
                setFullName(check)
                handleError(name, value)
            }
        }
        if (name === "email") {
            setEmail(value)
            handleError(name, value)
        }
        if (name === "mobile") {
            let check = value
            if (numberText(check)) {
                setMobile(check)
                handleError(name, value)
            }
        }
        if (name === "service") {
            setService(value)
            handleError(name, value)
        }
        if (name === "appointmentDate") {
            setAppointmentDate(value)
            handleError(name, value)
        }
        if (name === "appointmentTime") {
            setAppointmentTime(value)
            handleError(name, value)
        }
        if(name === "textmessage"){
            setTextmessage(value)
        }
    }  
    
    const handleError = (name, value) => {

        let allState = errors;

        if (name === "fullName") {
            if (!value.length) {
                allState["errfullName"] = "Please enter your full name."
            } else if (value.length < 3) {
                allState["errfullName"] = "Name length should be atleast 3 characters."
            } else {
                allState["errfullName"] = ""
            }
        }
        if (name === "email") {
            if (!value.length) {
                allState["errEmail"] = "Please enter your Email address."
            } else if (!isValidEmail(value)) {
                allState["errEmail"] = "Invalid Email address."
            } else {
                allState["errEmail"] = ""
            }
        }
        if (name === "mobile") {
            if (!value.length) {
                allState["errMobileno"] = "Please enter your Mobile No."
            } else if (!isValidMobileNo(value)) {
                allState["errMobileno"] = "Invalid Mobile No."
            } else {
                allState["errMobileno"] = ""
            }
        }
        if (name === "service") {
            if (!value.length) {
                allState["errService"] = "Please select a service."
            } else {
                allState["errService"] = ""
            }
        }
        if (name === "appointmentDate") {
            if (value === null) {
                allState["errAppointmentDate"] = "Please select an Appointment Date."
            } else {
                allState["errAppointmentDate"] = ""
            }
        }
        if (name === "appointmentTime") {
            if (!value.length) {
                allState["errAppointmentTime"] = "Please select an Appointment Time."
            } else {
                allState["errAppointmentTime"] = ""
            }
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }  
    
    const checkVailidation = () => {
        hasError = false;
        let allState = errors;

        if (!fullName.length) {
            allState["errfullName"] = "Please enter your full name."
            hasError = true;
        } else if (fullName.length < 3) {
            allState["errfullName"] = "Name length should be atleast 3 characters."
            hasError = true;
        } else {
            allState["errfullName"] = ""
        }
        if (!email.length) {
            allState["errEmail"] = "Please enter your email address."
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email address."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }
        if (!mobile.length) {
            allState["errMobileno"] = "Mobile no is required."
            hasError = true
        } else if (!isValidMobileNo(mobile)) {
            allState["errMobileno"] = "Invalid mobile number."
            hasError = true
        } else {
            allState["errMobileno"] = ""
        }
        if (!service.length) {
            allState["errService"] = "Please select a service."
            hasError = true
        } else {
            allState["errService"] = ""
        }
        if (appointmentDate === null) {
            allState["errAppointmentDate"] = "Please select an Appointment Date."
            hasError = true
        } else {
            allState["errAppointmentDate"] = ""
        }
        if (!appointmentTime.length) {
            allState["errAppointmentTime"] = "Please select an Appointment Time."
            hasError = true
        } else {
            allState["errAppointmentTime"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }  

    const handelBookAnAppointment = async (e) => {
        let formattedDate = moment(appointmentDate).format("DD/MM/yyyy")
        e.preventDefault()
        if(!checkVailidation()){

        setLoading(true)
        let data = {
            "fullname": fullName,
            "email": email,
            "mobileno": mobile,
            "service": service,
            "appointment_date": formattedDate,
            "appointment_time":appointmentTime,
            "textmessage": textmessage
        }

        try{
            const response = await fetch(`${baseUrl}/userRequest`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
            });
            const res = await response.json();
            if(res.status === 200){
                setLoading(false)
                navigate("/thank-you", {state: "book&appointment"})
            }else{
                setLoading(false)
                alert("server error")
            }
            }catch(error) {
                setLoading(false)
                console.log(error)
                alert("internal server error")
            } 
        }
    }

    return (
        <div className='wrapper book-an-appointment'>

            <section className='banner'>
                <figure>
                    <img src={BookAnAppointmentBanner} alt="Book An Appointment Banner" title="" />
                </figure>
            </section>

            <div className='main-div'>
                <section className='bread'>
                    <div className='container'>
                        <div className="breadcrumb-nav">
                            <ul className="breadcrumb">
                                <li><Link to={'/'}>Home</Link></li> 
                                <li className='active'>Book An Appointment</li>
                            </ul>
                        </div>
                    </div>
                </section>
                
                <section className='book-an-appointment-section'>
                    <div className='container'>
                        <div className='book-an-appointment-form'>
                            <img src={Ellipse} alt="Ellipse" title="" className='ellipse' />
                            <img src={BookAppointment} alt="BOOK AN APPOINTMENT" title="" className='calender' />
                            <h2>Book An Appointment</h2>
                            <p>Schedule an appointment with our expert representative</p>
                                
                            <form className="form">
                                <div className="form-group">
                                    <label>Full Name</label>
                                    <TextInput
                                        name={"fullName"}
                                        id={"fullName"}
                                        type={"text"}
                                        onBlur={BlurHandler}
                                        inputClass={"form-control"}
                                        value={fullName}
                                        onChange={ChangeHandler}
                                        autoComplete="off"
                                        errorMSg={errors.errfullName}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Mobile</label>
                                    <div className="cust-select">
                                        <div className='std-code'>+91</div>
                                        <TextInput
                                            name={"mobile"}
                                            id={"mobile"}
                                            type={"tel"}
                                            onBlur={BlurHandler}
                                            maxLength={10}
                                            inputClass={"form-control"}
                                            value={mobile}
                                            onChange={ChangeHandler}
                                            autoComplete="off"
                                            errorMSg={errors.errMobileno}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextInput
                                        name={"email"}
                                        id={"email"}
                                        type={"text"}
                                        onBlur={BlurHandler}
                                        inputClass={"form-control"}
                                        value={email}
                                        onChange={ChangeHandler}
                                        autoComplete="off"
                                        errorMSg={errors.errEmail}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Service</label>
                                    <Reactselect
                                        name={"service"}
                                        id={"service"}
                                        options={serviceData}
                                        value={service}
                                        onChange={ChangeHandler}
                                        onBlur={handleError}
                                        errorMsg={errors.errService}
                                        class={"form-select"}
                                    />
                                </div>

                                <ul className="select">
                                    <li className="form-group">
                                        <label>Appointment Date</label>
                                        <DatePicker 
                                        name={"appointmentDate"}
                                        id={"appointmentDate"}
                                        onBlur={BlurHandler}
                                        selected={appointmentDate} 
                                        dateFormat = {"dd/MM/yyyy"}
                                        placeholderText = {"DD/MM/YYYY"}
                                        className={"form-control"}
                                        value={appointmentDate}
                                        onChange={(date) => ChangeHandler("appointmentDate",date)}
                                        />
                                        <div className="error-message">{errors.errAppointmentDate}</div>
                                    </li>
                                    <li className="form-group">
                                        <label>Appointment Time</label>
                                        <Reactselect
                                        name={"appointmentTime"}
                                        id={"appointmentTime"}
                                        options={appointmentTimeData}
                                        value={appointmentTime}
                                        onChange={ChangeHandler}
                                        onBlur={handleError}
                                        errorMsg={errors.errAppointmentTime}
                                        class={"form-select"}
                                    />
                                    </li>

                                </ul>
                                
                                <div className="form-group">
                                    <label>Remarks (Optional)</label>
                                    <textarea 
                                        className='form-contrrol'
                                        placeholder='Type here your query'
                                        onChange={(e) => ChangeHandler("textmessage", e.target.value)}
                                        name="textmessage"
                                        value={textmessage}
                                        rows="3"
                                        onBlur={handleError}
                                    />  
                                </div>

                                <div className="submit"><button className="btn btn-yellow" type="submit" onClick={handelBookAnAppointment}>SUBMIT</button></div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            {loading &&
                <PageLoader />
            }
        </div>
    )
}

export default BookAnAppointment;