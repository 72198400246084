import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import AboutBanner from '../../assets/images/about-banner.jpg';
import CustomerFirst from '../../assets/images/icons/customer-first.svg';
import QualityFocus from '../../assets/images/icons/quality-focus.svg';
import Dignity from '../../assets/images/icons/dignity.svg';
import OurMission from '../../assets/images/our-mission.png';
import WhyChooseUs from '../../assets/images/why-choose-us.png';
import Plus from '../../assets/images/icons/plus.svg';
import Minus from '../../assets/images/icons/minus.svg';
import Welcome from '../Welcome';
import {Accordion,AccordionBody,AccordionHeader,AccordionItem,} from 'reactstrap';

const Data = [
    {
        "id": 1,
        "title": "EXPERIENCED TEAM",
        "content": "First and foremost, We are dedicated to providing the best customer service in the industry. Our staff is well trained. friendly and courteous customer service representatives will be there to provide you with helpful information and answer all your questions."
    },
    {
        "id": 2,
        "title": "AFFORDABLE SERVICE PRICES",
        "content": "We fully understand our customer’s expectations and make them feel completely secure with us by synergizing our energies through our network. We shall strive to continuously improve the effectiveness of the quality management system by enhancing the skill and competencies of our Team members."
    },
    {
        "id": 3,
        "title": "CUSTOMER SUPPORT",
        "content": "We are aware of that, communication is most important in our trade. Our all engineer / staff coordinate with our client 24x7 .We believe its is our superior customer service that sets us apart from competition"
    },
    {
        "id": 4,
        "title": "FAST, FRIENDLY SERVICE",
        "content": "We are committed to achieve customer delight by offering reliable, competent and comprehensive Service and solutions that conforms to Industry."
    },
    {
        "id": 5,
        "title": "15 YEARS EXPERIENCE",
        "content": "We have a team of highly skilled & experienced technicians specialized in air conditioning installations, repairs, servicing and maintenance. With over 15 years of experience, we are a trusted name in Air Conditioning service in Mumbai."
    }
]

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [open, setOpen] = useState('0');
    const toggle = (i) => {
        if (open === i) {
        setOpen();
        } else {
        setOpen(i);
    }
  };


    // const [accordionData, setAccordionData] = useState(Data);

    // const toggle = (id) => {
    //     let data = Data;
    //     data.forEach(item => {
    //         if(item.id === id){
    //             item.open = !item.open
    //         }
    //     })
    //     setAccordionData(data)
    // };


    return (
        <div className='wrapper about'>

            <section className='banner'>
                <figure>
                    <img src={AboutBanner} alt="About Banner" title="" />
                </figure>
            </section>

            <section className='bread'>
                <div className='container'>
                    <div className="breadcrumb-nav">
                        <ul className="breadcrumb">
                            <li><Link to={'/'}>Home</Link></li> 
                            <li className='active'>About</li>
                        </ul>
                    </div>
                </div>
            </section>
            
            <section className='about-section'>
                <div className='container'>
                    <div className='about'>
                        <h2>About</h2>
                        <p><span>Paras Air Conditioning</span> have entered into this growing and competitive industry in the year 2005 ,started singled handed, now we have manpower of 10 people which works across in and our of Mumbai City. Through sheer determination , hard work and, most importantly, the confidence shown by our clients, today PARAS successfully manage a large client portfolio, across the Kandivali and Malad location. Our service portfolio is created with the customers in mind.</p>
                        <p>At <span>Paras</span> we are constantly evolving our services</p>
                        <p>We are specialized in the installation, commissioning and after care services for air conditioning and ventilation systems for the commercial, retail and as well as. residential.</p>
                        <p>In fact, it is a challenge that we undertake daily. Today, we are constantly refining our suite of services to cater to business houses of all sizes.</p>
                    </div>
                </div>
            </section>

            <section className='welcome'>
                <div className='container'>
                    <Welcome />
                </div>
            </section>

            <section className='our-missions'>
                <div className='outer-div'>
                    <div className='container'>
                    <h2>Our Missions</h2>
                    <ul className='inner-ul'>
                        <li>
                            <ul>
                                <li>
                                    <img src={CustomerFirst} alt='Customer First' title='Customer First'/>
                                </li>
                                <li>
                                    <h5>Customer First</h5>
                                    <p>We exist and prosper only because of our customers. All our efforts are directed towards giving maximum customer satisfaction by providing them with value added services and understanding their needs adaptable to their changing life style, as we value them the most.</p>
                                    <p>We continuously evaluate our customer's needs, desires & feedbacks and try to fulfill their requirements by providing them with value for money solutions.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <img src={QualityFocus} alt='Quality Focus' title='Quality Focus'/>
                                </li>
                                <li>
                                    <h5>Quality Focus</h5>
                                    <p>Quality is the key to delivering value for money to our customers. We will make quality a driving value in our work, in our services and in our interactions with others. We will do it "first time right</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <img src={Dignity} alt='Dignity of the Individual' title='Dignity of the Individual'/>
                                </li>
                                <li>
                                    <h5>Dignity of the Individual</h5>
                                    <p>We value individual dignity. We inspire & enable people to achieve high expectations, standards & challenging goals. Through our actions, we nurture fairness, trust and transparency.</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    </div>
                </div>
                <figure className='half-img'>
                    <img src={OurMission} alt="Our Mission" title="Our Mission" className='' />
                </figure>
            </section>

            <section className='why-choose-us'>
                <div className='outer-div'>
                    <figure>
                        <img src={WhyChooseUs} alt="Why Choose Us" title="Why Choose Us" />
                    </figure>
                    <figcaption className='our-mission'>
                        <div className="faq-accordion">
                            {
                                Data.map((items, index) => {
                                    return (
                                        <Accordion open={`${open}`} toggle={toggle} key={index}>
                                            <AccordionItem>
                                                <AccordionHeader targetId={`${index}`}>{items.title}
                                                    <span className='plusicon'><img src={Plus} alt="Plus" title="" /></span>
                                                    <span className='minusicon'><img src={Minus} alt="Minus" title="" /></span>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`${index}`}>
                                                    <p>{items.content}</p>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    )
                                })
                            }
                        </div>
                    </figcaption>
                </div> 
            </section>
        </div>
    )
}

export default About;