import { regEx } from './constant'
// const crypto = require("crypto-js");


export function isValidMobileNo(mobileNo) {
    const panRegex =new RegExp(regEx.mobileNumber);
    return panRegex.test(mobileNo)
}

export function numberText(data) {
    let check =new RegExp(regEx.number);
    return check.test(data)
}

export function isValidEmail(data) {
    let check =new RegExp(regEx.email);
    return check.test(data)
}

export function alphabatesText(data) {
    let check =new RegExp(regEx.alphabates);
    return check.test(data)
}

export function alphabatesWithSpace(data) {
    let check =new RegExp(regEx.alphabatesWithSpace);
    return check.test(data)
}

export function alphaNumeric(data) {
    let check =new RegExp(regEx.alphaNumeric);
    return check.test(data)
}

export function emailOrMobileNumber(data) {
    let check =new RegExp(regEx.emailOrMobileNumber);
    return check.test(data)
}


export function fetchWithWait({ dispatch, action }) {
    return new Promise((resolve, reject) => {
        dispatch({ ...action, resolve, reject })
    })
}


export function generateOrderId() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    let orderId = result + Date.now()
    return orderId;
}

export function generateCartId() {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    let orderId = result + Date.now()
    return orderId;
}