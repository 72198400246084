import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import AirConditionRepairAndServicesBanner from '../../assets/images/services/air-condition-repair-and-services.jpg';
import BookAppointment from '../molecules/appointment';

const AirConditionRepairAndServices = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    console.log("navigatenavigatenavigate", location)

    const { state } = location

    return (
        <div className='wrapper air-condition'>

            <section className='banner'>
                <figure>
                    <img src={AirConditionRepairAndServicesBanner} alt="Air Condition Repair and Services Banner" title="" />
                </figure>
            </section>

            <div className='container'>
                <div className="breadcrumb-nav">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li> 
                        <li><Link to={'/services'}>Services</Link></li> 
                        <li className='active'>Air Condition Repair and Services</li>
                    </ul>
                </div>
            </div>
            
            <section className='ac'>
                <div className='container'>
                    <div className='ac-details'>
                        <div className='content'>
                            <h2>Air Condition Repair and Services</h2>
                            <p>Air conditioning systems are essential for keeping our homes and workplaces comfortable during hot and humid weather. However, like any mechanical system, air conditioners can experience malfunctions and breakdowns over time. If your air conditioner is not functioning properly, it may be time to call a PARAS AC.</p>
                            <p>We offer a range of services to ensure your air conditioner is running efficiently and effectively. Here are some common air conditioning repair and service options:</p>
                            <ul>
                                <li><span>Inspection and Maintenance:</span> Our professional technician will inspect and maintain your air conditioner to ensure it is functioning correctly. This includes checking the refrigerant levels, cleaning the coils, and replacing filters.</li>
                                <li><span>Repairs:</span> If your air conditioner is not functioning properly, our technician can diagnose and repair any issues. Common issues include faulty compressors, broken fans, and leaks.</li>
                                <li><span>Replacement:</span> If your air conditioner is beyond repair or is not functioning efficiently, a technician may recommend a replacement. They can help you choose a new unit that fits your needs and budget.</li>
                                <li><span>Installation:</span> If you have purchased a new air conditioning unit, our technician can install it for you, ensuring that it is installed correctly and functioning properly.</li>
                            </ul>
                            <p>It's important to schedule regular air conditioning maintenance to prevent breakdowns and extend the lifespan of your unit. Additionally, replacing air filters every few months can improve air quality and reduce the strain on your air conditioner.</p>
                        </div>

                        <BookAppointment />
                    </div>
                </div>
            </section>
        </div>
    )
}


export default AirConditionRepairAndServices;